import Vue from 'vue'
//import './plugins/axios'
import './plugins/bootstrap-vue'
import router from './router'
import store from '@/store/index';
import App from './App.vue'
import { api } from '@/services/api'
import { i18n } from '@/i18n'

api.configure();

import { dateDefault, dateShort, dateTimeLong } from '@/filters/date.filter'
import capitalize from '@/filters/capitalize'
import value from '@/filters/value'
import bool from '@/filters/bool'
import myCurrency from '@/filters/myCurrency'
import boolOrAny from '@/filters/boolOrAny'
Vue.filter('capitalize', capitalize)
Vue.filter('value', value)
Vue.filter('bool', bool)
Vue.filter('my_currency', myCurrency)
Vue.filter('boolOrAny', boolOrAny)
Vue.filter('date', dateDefault)
Vue.filter('date_short', dateShort)
Vue.filter('date_time_long', dateTimeLong)

import { ToggleButton } from 'vue-js-toggle-button'
Vue.component('ToggleButton', ToggleButton)

import LoadingSpinner from '@/components/Shared/LoadingSpinner'
Vue.component('loading-spinner', LoadingSpinner)

import ErrorBox from '@/components/Shared/ErrorBox.vue'
Vue.component('error-box', ErrorBox)

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faSignOutAlt,
  faUser,
  faEdit,
  faUndo,
  faCheck,
  faTrash,
  faHome,
  faUsers,
  faBars,
  faCog,
  faFile,
  faPen,
  faEllipsisH,
  faExclamationCircle,
  faStar,
  faCheckSquare,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons'
library.add(
  faSignOutAlt,
  faUser,
  faEdit,
  faUndo,
  faCheck,
  faTrash,
  faHome,
  faUsers,
  faBars,
  faCog,
  faFile,
  faPen,
  faEllipsisH,
  faExclamationCircle,
  faStar,
  faCheckSquare,
  faEnvelope
)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap)

Vue.component('font-awesome-icon', FontAwesomeIcon)

import VueCurrencyFilter from 'vue-currency-filter'
Vue.use(VueCurrencyFilter, [
  {
    symbol: '€',
    thousandsSeparator: ' ',
    fractionCount: 0,
    fractionSeparator: ',',
    symbolPosition: 'back',
    symbolSpacing: true,
    avoidEmptyDecimals: ''
  },
  {
    name: 'currency_2',
    symbol: '€',
    thousandsSeparator: ' ',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'back',
    symbolSpacing: true,
    avoidEmptyDecimals: '00'
  }
])

import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    //key: 'AIzaSyCVrPo0bKssNxlRyZ1xy9pAafCylHOBvUA',
    key: 'AIzaSyDOu4uDbX3J3ozANU56s0WKtCvcn_cZB_8',
    libraries: 'places'
  }
})

import GmapVue from 'gmap-vue'
Vue.use(GmapVue, {
  load: {
    // [REQUIRED] This is the unique required value by Google Maps API
    key: 'AIzaSyCVrPo0bKssNxlRyZ1xy9pAafCylHOBvUA',
    //key: 'AIzaSyDOu4uDbX3J3ozANU56s0WKtCvcn_cZB_8',
    // [OPTIONAL] This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
    libraries: 'places',

    // [OPTIONAL] If you want to set the version, you can do so:
    v: '3.26',

    // This option was added on v3.0.0 but will be removed in the next major release.
    // If you already have an script tag that loads Google Maps API and you want to use it set you callback
    // here and our callback `GoogleMapsCallback` will execute your custom callback at the end; it must attached
    // to the `window` object, is the only requirement.
    //customCallback: 'MyCustomCallback',
  },

  // [OPTIONAL] If you intend to programmatically custom event listener code
  // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  // you might need to turn this on.
  autoBindAllEvents: false,

  // [OPTIONAL] If you want to manually install components, e.g.
  // import {GmapMarker} from 'gmap-vue/src/components/marker'
  // Vue.component('GmapMarker', GmapMarker)
  // then set installComponents to 'false'.
  // If you want to automatically install all the components this property must be set to 'true':
  installComponents: true,

  // Load the Google Maps API dynamically, if you set this to `true` the plugin doesn't load the Google Maps API
  dynamicLoad: false,
})

import VeeValidate from 'vee-validate'
//Vue.use(VeeValidate)
// to avoid error: 'The computed property 'fields' is already defined as a prop' in b-table
Vue.use(VeeValidate, { fieldsBagName: 'veeFields' });

import VueCookies from 'vue-cookies'
Vue.use(VueCookies, { expire: '10y' })

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

