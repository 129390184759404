import { render, staticRenderFns } from "./ActivityEdit.vue?vue&type=template&id=2dd0c434&scoped=true&"
import script from "./ActivityEdit.vue?vue&type=script&lang=ts&"
export * from "./ActivityEdit.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dd0c434",
  null
  
)

export default component.exports