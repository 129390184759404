import { render, staticRenderFns } from "./SortableColumns.vue?vue&type=template&id=5df33d6e&scoped=true&"
import script from "./SortableColumns.vue?vue&type=script&lang=ts&"
export * from "./SortableColumns.vue?vue&type=script&lang=ts&"
import style0 from "./SortableColumns.vue?vue&type=style&index=0&id=5df33d6e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5df33d6e",
  null
  
)

export default component.exports