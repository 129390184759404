import { render, staticRenderFns } from "./DealEdit.vue?vue&type=template&id=d0015a3e&scoped=true&"
import script from "./DealEdit.vue?vue&type=script&lang=ts&"
export * from "./DealEdit.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0015a3e",
  null
  
)

export default component.exports