import { render, staticRenderFns } from "./LeadEditRequest.vue?vue&type=template&id=afd702e6&scoped=true&"
import script from "./LeadEditRequest.vue?vue&type=script&lang=ts&"
export * from "./LeadEditRequest.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afd702e6",
  null
  
)

export default component.exports